import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Post Content',
    nickname: 'Post ContentWidget',
  });

  // widgetBuilder.configureConnectedComponents(
  //   postContentElementIds.paywallRichContentViewerId,
  //   (connectedComponentsBuilder) => {
  //     connectedComponentsBuilder.behavior().set({
  //       closed: {
  //         selectable: false,
  //         hideFromHierarchy: true,
  //       },
  //     });
  //   },
  // );

  // widgetBuilder.configureConnectedComponents(
  //   postContentElementIds.multiStateBoxId,
  //   (connectedComponentsBuilder) => {
  //     connectedComponentsBuilder.set({
  //       displayName: 'Select Rich Content Type',
  //     });
  //   },
  // );

  // widgetBuilder.configureConnectedComponents(
  //   postContentElementIds.paidContentId,
  //   (connectedComponentsBuilder) => {
  //     connectedComponentsBuilder.set({
  //       displayName: 'Content with paywall',
  //     });
  //   },
  // );

  // widgetBuilder.configureConnectedComponents(
  //   postContentElementIds.freeContentId,
  //   (connectedComponentsBuilder) => {
  //     connectedComponentsBuilder.set({
  //       displayName: 'Content without paywall',
  //     });
  //   },
  // );
};
